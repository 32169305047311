/* You can add global styles to this file, and also import other style files */

$scale: 14px; /* main font size */
$borderRadius: 6px; /* border radius of layout element e.g. card, sidebar */
$transitionDuration: .2s; /* transition duration of layout elements e.g. sidebar, overlay menus */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";


.p-paginator-first,
.p-paginator-prev,
.p-paginator-next,
.p-paginator-pages,
.p-paginator-page,
.p-paginator-last,
.p-paginator-first {
    height: 2.5rem !important;
    min-width: 2.5rem !important;
}

//Faz o rating e o editor ficarem com o float label ativado
.p-float-label p-editor ~ label,
.p-float-label ckeditor ~ label,
.p-float-label p-rating ~ label {
    top: -0.75rem;
    font-size: 12px;
}

.p-input-icon-right > .p-icon-wrapper {
    right: 0.5rem;
}

//Fix para o inputmask funcionar com float label + input group
.p-inputgroup .p-float-label:first-child input {
    width: 100% !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

@media screen and (min-width: 1960px) {
    .layout-main,
    .landing-wrapper {
        width: 100%;
    }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
    padding: 0.75rem 1.25rem;
}
